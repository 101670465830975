import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby";
import Section, { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'

const CTASection = ({
    sectionStyle,
    heading,
    text,
    ButtonOne,
    ButtonTwo,
    ...props
}) => {
    const ctaData = useStaticQuery(graphql `
        query CtaImgQuery {
            file(relativePath: {eq: "images/bg/cta-bg-2.png"}) {
                childImageSharp {
                    fluid(maxWidth: 711, maxHeight: 280, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const imageData = ctaData.file.childImageSharp.fluid;
    return ( 
        <Section {...sectionStyle} bgImage={imageData}>
            <Container>
                <Row className="align-items-center text-lg-left text-center" >
                    <Col xl={6} lg={6}>
                        <Heading {...heading}>Get your community on board with a SignPuns managed sign and <span>bring a weekly dose of happiness</span> to your community</Heading> 
                    </Col> 
                    <Col xl ={6} lg={6} className="text-center">
                        <Button 
                            to="/contact-us" 
                            {...ButtonOne} 
                            icon="far fa-comment-alt-dots"
                            iconposition="left"
                            icondistance="8px"
                            iconsize="16px">
                            Let 's talk 
                        </Button> 
                        <Button 
                            to="/about-us" 
                            {...ButtonTwo} 
                            icon="far fa-info-circle"
                            iconposition="left"
                            icondistance="8px"
                            iconsize="16px">
                            Get Info 
                        </Button>
                        <Button 
                            to="/" 
                            {...ButtonTwo} 
                            icon="far fa-info-circle"
                            iconposition="left"
                            icondistance="8px"
                            iconsize="16px">
                            Sign up
                        </Button> 
                    </Col> 
                </Row> 
            </Container> 
        </Section>
    )
}

CTASection.propTypes = {
    heading: PropTypes.object
}

CTASection.defaultProps = {
    sectionStyle: {
        bgColor: '#454545',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    heading: {
        as: 'h3',
        color: '#fff',
        child: {
            color: 'secondary'
        },
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    ButtonOne: {
        skin: 'light',
        m: '7px'
    },
    ButtonTwo: {
        skin: 'secondary',
        m: '7px'
    }
}


export default CTASection